<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" align="center" class="mx-0 px-0">
                <div class="image-wrapper pb-1">
                    <v-img height="auto" width="300px" :src="require('@/assets/main-logo.jpg')" />
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="login-form mx-0 px-0" align="center">
                <login-form @setAlert="setAlert" />
            </v-col>
        </v-row>
        <alert :show-alert="showAlert" :message="message" :type="type" />
    </v-container>
</template>

<script>
    import LoginForm from '@/forms/LoginForm'
    import Alert from '@/components/Alert'

    export default {
        components: { LoginForm, Alert },
        data() {
            return {
                showAlert: false,
                message: '',
                type: null
            }
        },
        watch: {
            tab: {
                handler(newval, oldval) {
                    if (newval) {
                        if (this.showAlert !== false) {
                            this.showAlert = false
                        }
                    }
                }
            },
            showAlert: {
                handler(newval, oldval) {
                    if (newval !== oldval) {
                        setTimeout(() => { this.showAlert = false }, 7000)
                    }
                }
            }
        },
        created() {
        },
        methods: {
            setAlert(message, type) {
                this.showAlert = true
                this.message = message
                this.type = type
            }
        }
    }
</script>

<style scoped>

.login-form{
    width: 300px;
}
.error-message {
    display: flex;
    justify-content: center;
    color: red;
    font-weight: bold;
}
.image-wrapper{
    width: 50%;
    margin: 0 auto;
}

</style>
