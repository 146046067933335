<template>
    <v-form
        class="login-form"
        style="padding-top: 5vh"
        @keyup.native.enter="enterKeySubmit"
    >
        <v-text-field
            v-model="email"
            label="Email"
            class="form-field"
            required
        />
        <v-text-field
            v-model="password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            label="Κωδικός"
            hint="Μέγιστο 18 χαρακτήρες"
            value=""
            class="input-group--focused form-field"
            @click:append="show = !show"
        />
        <div
            class="submit-button-wrapper"
        >
            <v-btn
                class="white--text"
                color="#3949AB"
                :disabled="shouldDisableSubmit()"
                :loading="dialog"
                @click="submit"
            >
                Εισοδος
            </v-btn>
        </div>
    </v-form>
</template>

<script>
    import store from '@/store'
    import userService from '@/services/user'

    export default {
        props: {
        },
        data() {
            return {
                show: false,
                dialog: false,
                email: '',
                password: '',
                message: ''
            }
        },
        methods: {
            enterKeySubmit() {
                if (!this.shouldDisableSubmit()) {
                    this.submit()
                }
            },
            shouldDisableSubmit() {
                if (!this.password || !this.email) {
                    return true
                }
                return false
            },
            submit() {
                this.dialog = true
                userService.login({
                    username: this.email,
                    password: this.password
                }).then(resp => {
                    this.dialog = false
                    store.dispatch('login', [resp]).then(() => this.$router.push('/'))
                }).catch(error => {
                    this.error = error.response.data
                    this.message = error.response.data.message
                    this.$emit('setAlert', this.message, 'error')
                    this.dialog = false
                })
            }
        }
    }
</script>

<style scoped>
    .login-form {
        margin-bottom: 5%;
    }
    .form-field {
        width: 90%;
        margin: 0 auto;
    }
    .submit-button-wrapper {
        display: flex;
        justify-content: center;
    }

</style>
